<template>
  <section>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="author.bio" v-html="author.bio.html" />
    <div v-if="articles && author.posts.length" class="mt-4">
      <h3 class="is-size-3">Articles</h3>
      <CardList
        :items="author.posts"
        :card="ArticleCard"
        :placeholders="3"
        :loading="$apollo.loading"
      />
    </div>
  </section>
</template>

<script>
import { AUTHOR } from "@/models/resources/operations.gql";
import CardList from "@/components/common/CardList.vue";
import ArticleCard from "@/components/resources/ArticleCard.vue";

export default {
  name: "AuthorCard",
  components: {
    CardList
  },
  props: {
    username: {
      type: String,
      required: true
    },
    articles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ArticleCard,
      author: {
        posts: []
      }
    };
  },
  apollo: {
    author: {
      // Should query this only if `articles` is true
      client: "cms",
      query: AUTHOR,
      variables() {
        return {
          username: this.username
        };
      },
      update({ author }) {
        // If author comes back null (?), use the default this.author
        return author
          ? {
              ...author,
              posts: author.posts.filter(({ collections }) => {
                // Filter each post's collections for any post that is in Public collection role
                return collections.reduce(
                  (isPublic, { role }) => isPublic || role === "Public",
                  false
                );
              })
            }
          : this.author;
      }
    }
  }
};
</script>
