<template>
  <HeaderPage
    v-if="!$apollo.loading && post"
    :title="post.title"
    image-class="article-header"
    class="article-header-page"
  >
    <template #summary>
      <strong :class="!post.tagline ? 'pull-mt-size-7' : ''">{{
        post.tagline
      }}</strong>
    </template>
    <template #body>
      <div v-if="post.authors.length" class="article-hero">
        <div class="published">
          <span>By</span>
          <span
            v-for="(a, i) in post.authors"
            :key="`${post.id}-author-by-${i}`"
          >
            <router-link class="has-text-info"
              v-if="a.username"
              :to="{ name: 'user_profile', params: { username: a.username } }"
            >
              {{ a.name }}
            </router-link>
            <span v-else>{{ a.name }}</span>
          </span>
          <span v-if="post.publishedDate"
            >on {{ published | format("MMM d, yyyy") }}</span
          >
        </div>
        <div class="share-button">
          <CopyUrl type="has-text-info" :title="post.title" />
        </div>
      </div>
      <center>
        <img
          v-if="post.media && post.media[0] && post.media[0].url"
          :src="post.media[0].url"
          class="mb-1"
        />
      </center>
      <Section class="article-content">
        <div
          v-for="(c, i) in post.content"
          :key="`${post.id}-block-${i}`"
          :class="`${c.type.toLowerCase()}-block`"
        >
          <a v-if="c.anchor" :name="c.anchor" />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-if="c.type === 'Text'" v-html="c.copy.html" />
          <div v-else-if="c.type === 'Image'">
            <img :src="c.media[0] ? c.media[0].url : c.url" />
          </div>
          <Accordian
            v-if="c.type === 'Accordion'"
            class="my-2"
            :icon-url="(c.media[0] || c).url"
            is-open
          >
            <template #question>{{ c.description }}</template>
            <template #answer>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="c.copy.html" />
            </template>
          </Accordian>
        </div>
      </Section>
      <Section v-if="post.authors.length" class="article-author">
        <div class="horizontal-gradient-rule" />
        <br />
        <span
          v-for="(a, i) in post.authors"
          :key="`${post.id}-author-bio-${i}`"
        >
          <h6 class="has-text-weight-bold">Published by {{ a.name }}</h6>
          <AuthorCard :username="a.username" class="my-2" />

          <h6 style="font-weight: bold">More Posts by {{ a.name }}</h6>
          <ArticleList :where="wherePostsByAuthors" :card="ArticleCard" />
        </span>
      </Section>
    </template>
  </HeaderPage>
  <Page v-else-if="$apollo.loading">
    <Section>
      <b-skeleton size="is-large" width="60%" />
      <div class="my-2">
        <b-skeleton width="20%" />
      </div>
      <div class="my-2">
        <b-skeleton width="100%" height="50vh" />
      </div>
      <div class="my-2">
        <b-skeleton width="100%" />
        <b-skeleton width="100%" />
        <b-skeleton width="85%" />
      </div>
    </Section>
  </Page>
</template>

<script>
import { toLocalDate } from "@/dates.js";
import { POST } from "@/models/resources/operations.gql";
import ArticleList from "@/components/resources/ArticleList.vue";
import ArticleCard from "@/components/resources/ArticleCard.vue";
import AuthorCard from "@/components/resources/AuthorCard.vue";
import Accordian from "@/components/resources/Accordian.vue";
import Page from "@/components/common/Page.vue";
import HeaderPage from "@/components/common/HeaderPage.vue";
import Section from "@/components/common/Section.vue";
import CopyUrl from "@/components/common/CopyUrl.vue";

export default {
  name: "ResourcePost",
  metaInfo: {
    title: "Article - "
  },
  components: {
    Page,
    HeaderPage,
    Section,
    Accordian,
    AuthorCard,
    ArticleList,
    CopyUrl
  },
  props: {
    slug: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ArticleCard,
      post: { related: [] },
      limit: 6
    };
  },
  computed: {
    published() {
      return toLocalDate(this.post.publishedDate);
    },
    wherePostsByAuthors() {
      return {
        id_in: this.post.authors.reduce(
          (postIds, author) => postIds.concat(author.posts.map(p => p.id)),
          []
        )
      };
    }
  },
  apollo: {
    post: {
      client: "cms",
      query: POST,
      variables() {
        const stage =
          !!localStorage.showCMSDrafts && JSON.parse(localStorage.showCMSDrafts)
            ? "DRAFT"
            : "PUBLISHED";
        return {
          stage,
          where: { slug: this.slug || this.$route.params.slug }
        };
      },
      update({ post }) {
        return post;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.article-header-page {
  .horizontal-gradient-rule {
    height: 3px;
    background-image: linear-gradient(#ffbf60, #ffcb37, #ffbf60);
    margin-top: 8px;
  }
  .pull-mt-size-7 {
    margin-top: -$size-7;
  }
  .article-header {
    background: $grey900;
    @include mobile {
      height: 25vw;
    }
    @include portrait {
      height: 45vw;
    }
    @include tablet {
      height: 200px;
    }
    .container {
      padding-top: 0;
    }
    h1 {
      @include mobile {
        font-size: vwl(36px);
      }
      @include portrait {
        font-size: vwp(36px);
      }
    }
  }
  .article-hero {
    padding: 2.5rem 0 0.5rem 0;
    margin-bottom: 2rem;
    border-bottom: 3px solid $gold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
      padding: vwl(30px) 0 vwl(8px) 0;
      margin-bottom: vwl(15px);
    }
    @include portrait {
      padding: vwp(20px) 0 vwp(10px) 0;
      margin-bottom: vwp(15px);
    }
    .published {
      font-size: 1.1em;
      font-style: italic;
      font-weight: 500;
      margin-top: 5px;
      @include mobile {
        font-size: vwl(12px);
        margin-top: vwl(5px);
      }
      @include portrait {
        font-size: vwp(12px);
        margin-top: vwp(5px);
      }
    }
  }
  .container {
    max-width: 885px !important;
  }
  .article-content {
    font-family: $family-primary;
    font-size: 16px;
    line-height: 1.5;
    $margin: 1.75em;
    p {
      margin: 1em 0;
    }
    h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
      // override global h* + p rules
      font-size: inherit;
      margin-top: 1em;
      font-weight: inherit;
    }
    h2 + ol, h2 + ul {
      margin-top: $margin / 2;
    }
    blockquote {
      padding: $margin / 2;
      border: 2px solid #ffcb37;
      border-left: 5px solid #ffcb37;
      background-color: #f9f9f9;
      margin: $margin $margin/2;
    }
    ul, ol {
      margin: $margin 0;
      padding-left: $margin/2;
    }
    ul {
      list-style-type: disc;
      text-indent: -0.9em;
    }
    ol {
      text-indent: -0.9em;
    }
    ol li::marker {
      font-weight: bold;
    }
    h1 {
      font-weight: 500 !important;
      @include tablet {
        font-size: 28px !important;
      }
      @include mobile {
        font-size: 22px;
      }
    }
    h2 {
      font-weight: 500;
      @include tablet {
        font-size: 20px;
      }
      @include mobile {
        font-size: 18px;
      }
    }
    h1 + h2 {
      margin-top: 1em;
    }
    h1 + ul {
      margin-top: 1em;
    }
    h1 + ol {
      margin-top: 1em;
    }
    .image-block {
      text-align: center;
    }
    .answer p {
      margin: 0;
    }
    table {
      border: 1px solid black;
      th {
        border: 1px solid black;
        padding: 5px 10px;
      }
      td {
        border: 1px solid black;
        padding: 5px 10px;
      }
    }
    a {
      border-bottom: solid 1px transparent;
      transition: 0.2s;
      &:hover,
      &:focus {
        color: shade($blue, 20%);
        border-bottom: solid 1px shade($blue, 20%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,.75) 100%);
      }
    }
  }
}
</style>
